@import '../../../utils/base.scss';
.nav{
    display: flex;
    flex-direction: column;
    &__logoContainer{
        width: 100%;
        padding: 10px 0;
    }
    &__logo{
        height: 48px;
        margin: auto 0;
    }
    &__menuContainer{
        background-color: $primary_color;
    }
    &__linksContainer{
        display: flex;
        a{
            color: black;
            padding: 20px 12px;
            position: relative;
            display: block;
            transition: all 0.5s;
            border-radius: 10px;
            &:hover{
                text-decoration: none;
                color: white;
                background-color: $primary_color;
                font-weight: bold;
            }
        }
        .active{
            + .nav__sublinksContainer{
                display: flex;
            }
        }
    }
    &__sublinksContainer{
        position: absolute;
        display: none;
        flex-direction: column;
        a{
            color: black;
        }
    }
}