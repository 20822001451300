.App{
    min-height: 100vh;
}
@media (min-width: 1200px){
    .container{
        max-width: 1200px !important;
    }
}
.btn-success{
    background-color: #0976bc !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
    background-color: #FEC831 !important;
}
.react-datepicker__year-container-text{
    text-align: center !important;
    cursor: pointer;
}
.react-datepicker__year{
    width: 200px !important;
}