@import '../../utils/base.scss';
._{
    &container{
        display: flex;
        height: 100vh;
        padding: 20px;
        background-image: url(../../assets/images/signin_bg.svg);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: right;
    }
    &signInCard{
        min-height: 500px;
        width: 100%;
        max-width: 500px;
        h2{
            font-size: 36px;
            font-weight: bold;
            color: $primary_color;
        }
    }
    &signInForm{
        button{
            width: 100%;
            height: 50px;
            background-color: $primary_color;
            font-weight: bold;
            border: none;
            outline: none;
            &:hover,&:focus{
                background-color: $accent_color;
            }
        }
    }
}